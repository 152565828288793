import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import RouteAutosuggest from '../../elements/routes/RouteAutosuggest';
import {
  reverseRouteDirections,
  setRouteArrival,
  setRouteDeparture,
  setTaggingArrivalPoint, setTaggingStartingPoint,
} from '../../../actions/RouteActions';

const RoutesSearchAutosuggests = () => {
  const { departure, arrival } = useSelector(state => state.routes);
  const lang = useSelector(state => state.i18n.locale);
  const dispatch = useDispatch();
  return (
    // eslint-disable-next-line no-nested-ternary
    <div className={`display-flex align-items-center route-search react-autosuggest ${lang === 'es' ? 'lang-es' : lang === 'en' ? 'lang-en' : ''}`}>
      <div className="col-xs-12">
        <RouteAutosuggest
          placeholder="route.search.from"
          placeholderExample="route.search.example"
          defaultValue={departure}
          taggingAction={taggingValue => dispatch(setTaggingStartingPoint(taggingValue))}
          action={route => dispatch(setRouteDeparture(route))}
          fieldType="departure"
        />
        <RouteAutosuggest
          placeholder="route.search.to"
          placeholderExample="route.search.example"
          defaultValue={arrival}
          taggingAction={taggingValue => dispatch(setTaggingArrivalPoint(taggingValue))}
          action={route => dispatch(setRouteArrival(route))}
          fieldType="arrival"
        />
      </div>

      <button type="button" className="route-switch" onClick={() => dispatch(reverseRouteDirections())}>
        <span className="visually-hidden">{Translate('route.a11y.switch')}</span>
        <svg width={17} height={26} className="f-white">
          <use xlinkHref="#icon-inverse" />
        </svg>
      </button>
    </div>
  );
};

export default RoutesSearchAutosuggests;
