import React from 'react';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SubtitleIcon from '../../elements/title/SubtitleIcon';
import RoutesSearchAutosuggests from './RoutesSearchAutosuggests';
import RoutesSearchDate from './RoutesSearchDate';
import RoutesSearchPreferences from './RoutesSearchPreferences';
import { formatRouteParams } from '../../../helpers/RoutesHelper';
import routes from '../../../routes';
import { pushRoute } from '../../../helpers/RouterHelper';
import { storeRecentEntities } from '../../../helpers/RecentSearchHelper';
import { ENTITY_PARAM } from '../../../config/map';
import { taggingEventSearchRoute } from '../../../tagging/routes/RoutesTaggingActions';
import { NAVITIA_TRANSPORT_TYPES, ROUTE_REPRESENT, ROUTE_TAGGING_ACTION } from '../../../config/routes';
import { generateDateLabel } from '../../../helpers/GTMHelper';
import { setWithAccessibility } from '../../../actions/RouteActions';

const RoutesSearchBlock = () => {
  const lang = useSelector(state => state.i18n.locale);
  const routesStore = useSelector(state => state.routes);
  const isMobile = useSelector(state => state.app.isMobile);
  const { withAccessibility } = useSelector(state => state.routes);

  const dispatch = useDispatch();
  const isHomepage = useSelector(state => state.app.isHomepage);

  const handleClick = () => {
    if (routesStore.departure && routesStore.arrival) {
      storeRecentEntities(routesStore.departure, ENTITY_PARAM.ADDRESS);
      storeRecentEntities(routesStore.arrival, ENTITY_PARAM.ADDRESS);

      const date = moment(routesStore.date);
      const direction = routesStore.isDepartureDate ? ROUTE_REPRESENT.DEPARTURE : ROUTE_REPRESENT.ARRIVAL;

      taggingEventSearchRoute({
        tbm_search_time_type: direction,
        tbm_search_day: date.format('DD MM YYYY'),
        tbm_search_time: date.format('HH[:]mm'),
        tbm_search_time_lag: `${direction} ${generateDateLabel(date)}`,
        tbm_starting_point: routesStore.taggingStartingPoint.length > 0 ? routesStore.taggingStartingPoint : ROUTE_TAGGING_ACTION.INPUT_ADDRESS,
        tbm_starting_point_address: routesStore.departure.label,
        tbm_arrival_point: routesStore.taggingArrivalPoint.length > 0 ? routesStore.taggingArrivalPoint : ROUTE_TAGGING_ACTION.INPUT_ADDRESS,
        tbm_arrival_point_address: routesStore.arrival.label,
        tbm_travel_preferences: routesStore.taggingPreferencesOpened || routesStore.wheelchair ? 1 : 0,
        tbm_travel_preference_tram: routesStore.transportModes.includes(NAVITIA_TRANSPORT_TYPES.TRAM) ? 1 : 0,
        tbm_travel_preference_bus: routesStore.transportModes.includes(NAVITIA_TRANSPORT_TYPES.BUS) ? 1 : 0,
        tbm_travel_preference_v3: routesStore.transportModes.includes(NAVITIA_TRANSPORT_TYPES.VCUB) ? 1 : 0,
        tbm_travel_preference_bat3: routesStore.transportModes.includes(NAVITIA_TRANSPORT_TYPES.BATCUB) ? 1 : 0,
        tbm_travel_preference_train: routesStore.transportModes.includes(NAVITIA_TRANSPORT_TYPES.TRAIN_TER) ? 1 : 0,
        tbm_travel_preference_autocar: routesStore.transportModes.includes(NAVITIA_TRANSPORT_TYPES.AUTOCAR) ? 1 : 0,
        tbm_travel_preference_voiture: routesStore.transportModes.includes(NAVITIA_TRANSPORT_TYPES.CAR) ? 1 : 0,
        tbm_travel_accessibility: routesStore.withAccessibility ? 1 : 0,
      });

      pushRoute(routes.ROUTE.RESULTS.name, lang, formatRouteParams(routesStore));
    }
  };

  const setAccessibleMode = (event) => {
    dispatch(setWithAccessibility(event.target.checked));
  };

  return (
    <>
      {
        !isHomepage
          ? <SubtitleIcon text="route.search.title" iconName="icon-search" size={30} additionalClassName={isMobile ? 'm-t' : ''} />
          : null
      }
      <div className={`routes ${isHomepage ? 'homepage' : ''}`}>
        <div className="tbm-block tbm-block-shadow filter-search filter-full routes-scroll-container">
          <RoutesSearchAutosuggests />
          <RoutesSearchDate />
          <RoutesSearchPreferences />
          <div className="row m-t-0-5 m-b-0-5 display-flex align-items-center">
            <div className="col-xs-12 col-xlg-12 m-t-0-25 m-md-t-0-5 p-md-l-0-5">
              <label htmlFor="accessibility_route" className="checkbox">
                <svg width="18" height="18">
                  <use xlinkHref="#icon-phmr" />
                </svg>
                <span className="label-checkbox">{Translate('route.search.accessibility')}</span>
                <input
                  id="accessibility_route"
                  type="checkbox"
                  value={withAccessibility}
                  onChange={setAccessibleMode}
                  checked={withAccessibility}
                />
                <span className="checkmark" />
              </label>
            </div>
          </div>
          <div className="text-center p-t-0-75">
            <button
              type="button"
              className="btn btn-blue route-submit"
              onClick={handleClick}
              disabled={!routesStore.departure || !routesStore.arrival}
            >
              {Translate('route.search.submit')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoutesSearchBlock;
