import React, {useState, useEffect} from 'react';
import {t as Translate} from '@vernouf/upgraded-react-redux-i18n';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import TimePicker from 'rc-time-picker';
import Collapsible from 'react-collapsible';
import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';
import {
  setIsDepartureDate,
  setRouteDate,
  setWithAccessibility,
} from '../../../actions/RouteActions';

const DEPARTURE_VALUE = 'departure';

const renderAddon = panel => (
  <div className="rc-time-picker-panel-close">
    <button type="button" onClick={() => panel.close()}>{Translate('route.close')}</button>
  </div>
);

const RoutesSearchDate = () => {
  const { isDepartureDate, date } = useSelector(state => state.routes);
  const [momentDate, setMomentDate] = useState(moment(date));
  const dispatch = useDispatch();
  const isHomepage = useSelector(state => state.app.isHomepage);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (moment(date).isBefore(moment(), 'day')) {
      setMomentDate(moment());
    } else if (moment(date).isSame(moment(), 'day')
      && moment(date).isSameOrBefore(moment(), 'minute')) {
      const dateWithOneMinute = moment();
      setMomentDate(dateWithOneMinute);
      dispatch(setRouteDate(dateWithOneMinute.format()));
    } else {
      setMomentDate(moment(date));
    }
  }, [date]);

  const handleRadioChange = (e) => {
    dispatch(setIsDepartureDate(e.target.value === DEPARTURE_VALUE));
  };

  const handleDateChange = (value) => {
    const dateHour = momentDate.hours();
    const dateMinutes = momentDate.minutes();

    const newDate = value;
    newDate.hours(dateHour);
    newDate.minutes(dateMinutes);

    dispatch(setRouteDate(newDate.format()));
  };

  const handleTimeChange = (value) => {
    dispatch(setRouteDate(value.format()));
  };

  const disabledDate = (current) => {
    const eachCalendarDate = moment();
    eachCalendarDate.hour(0);
    eachCalendarDate.minute(0);
    eachCalendarDate.second(0);

    return current.valueOf() < eachCalendarDate.valueOf();
  };

  const isToday = () => momentDate.isSame(moment(), 'day');
  const isCurrentHour = () => momentDate.isSame(moment(), 'hour');

  const disabledHours = () => {
    const currentHour = moment().format('HH');
    return isToday() ? [...Array(Number(currentHour)).keys()] : [];
  };

  const disabledMinutes = () => {
    const currentMinutes = moment().format('mm');
    return isToday() && isCurrentHour() ? [...Array(Number(currentMinutes)).keys()] : [];
  };

  const calendar = (
    <Calendar
      showDateInput={false}
      showToday={false}
      disabledDate={disabledDate}
    />
  );

  const renderAccordionschedules = () => (
    <div className="display-flex settings align-items-center align-content-stretch justify-space-between m-md-l-0-50 collapsible-header">
      <div className="label p-l-0 m-r-0-5">
        <span className="label text-blue">
          {
            isDepartureDate ? Translate('route.search.datetime_represents.departure') : Translate('route.search.datetime_represents.arrival')
          } :
        </span>
        <span className="label text-black font-regular">
          {
            isToday()
              ? (
                `${Translate('traffic.today')} ${Translate('traffic.at')} ${momentDate.format('HH[h]mm')}`
              )
              : (
                `${momentDate.format('LL')} ${Translate('traffic.at')} ${momentDate.format('HH[h]mm')}`
              )
          }
        </span>
      </div>
      <div className="collapse arrow">
        <svg width="15" height="15" className="f-blue">
          <use xlinkHref="#icon-arrow-down" />
        </svg>
      </div>
    </div>
  );

  return (
    <div className={`m-t-0-25 m-md-t-0 home-block m-b-0-5 ${isHomepage ? 'homepage' : ''}`}>
      <Collapsible
        trigger={renderAccordionschedules()}
        open={opened}
        onOpening={() => setOpened(true)}
        onClosing={() => setOpened(false)}
        tabIndex={0}
        triggerTagName="div"
        triggerClassName=""
      >
        <div className="col-xs-12 m-t-0-5 m-md-t-0-25 display-flex">
          <div htmlFor="datetime_represents_departure" className="radio col-xs-6">
            <input
              id="datetime_represents_departure"
              type="radio"
              className="radio-datetime-represents departure"
              name="datetime_represents"
              value={DEPARTURE_VALUE}
              onChange={e => handleRadioChange(e)}
              checked={isDepartureDate}
            />
            <label className="label-radio">{Translate('route.search.datetime_represents.departure')}</label>
          </div>
          <div htmlFor="datetime_represents_arrival" className="radio col-xs-6">
            <input
              id="datetime_represents_arrival"
              type="radio"
              className="radio-datetime-represents arrival"
              name="datetime_represents"
              value="arrival"
              onChange={e => handleRadioChange(e)}
              checked={!isDepartureDate}
            />
            <label className="label-radio">{Translate('route.search.datetime_represents.arrival')}</label>
          </div>
        </div>

        <div className="col-xs-12 text-left m-t-0-75 m-lg-t-1 ws-nw">
          <span className="label text-bold m-r-0-25 text-blue">{Translate('route.the')}</span>
          <DatePicker
            calendar={calendar}
            defaultValue={moment()}
            value={momentDate}
            onChange={handleDateChange}
            style={{zIndex: 1001}}
            animation="slide-up"
            placement="bottomRight"
          >
            {
              ({value}) => (
                <button
                  type="button"
                  tabIndex="0"
                  className="datepicker-input"
                >
                  {moment(value).format('ddd DD MMM')}
                  <input type="hidden" id="route-start-date" value={moment(value).format('DD MM YYYY')}/>
                </button>
              )
            }
          </DatePicker>

          <span className="label text-bold m-l-0-25 m-r-0-25 text-blue">{Translate('route.at')}</span>

          <TimePicker
            id="route-start-time"
            allowEmpty={false}
            showSecond={false}
            minuteStep={1}
            defaultValue={moment()}
            value={momentDate}
            disabledHours={disabledHours}
            disabledMinutes={disabledMinutes}
            onChange={handleTimeChange}
            addon={renderAddon}
            inputReadOnly
          />
        </div>
      </Collapsible>
    </div>
  )
  ;
};

export default RoutesSearchDate;
