import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import { useDispatch, useSelector } from 'react-redux';
import { setTaggingPrefereneces, switchRouteTransport } from '../../../actions/RouteActions';
import TransportModeSwitcher from '../../elements/routes/TransportModeSwitcher';
import {NAVITIA_TRANSPORT_TYPES, TRANSPORT_MODE_DISPLAYABLE, TRANSPORT_MODE_SORT_ORDER} from '../../../config/routes';

const RoutesSearchPreferences = () => {
  const withAccessibility = useSelector(state => state.routes.withAccessibility);
  const { transportModes, transportModesError } = useSelector(state => state.routes);
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();
  const isHomepage = useSelector(state => state.app.isHomepage);
  const isMobile = useSelector(state => state.app.isMobile);

  useEffect(() => {
    dispatch(setTaggingPrefereneces(opened ? 1 : 0));
  }, [opened]);

  useEffect(() => {
    if (withAccessibility) {
      setOpened(true);
    }
    if (isMobile) {
      setOpened(true);
    }
  }, [withAccessibility, isMobile, isHomepage]);

  const sortedTransportModes = transportModes.sort((a, b) => TRANSPORT_MODE_SORT_ORDER.indexOf(a) - TRANSPORT_MODE_SORT_ORDER.indexOf(b));

  const renderAccordionHeader = () => (
    <div className="display-flex settings align-items-center align-content-stretch justify-space-between m-md-l-0-50 collapsible-header">
      <div className="label p-l-0 m-r-0-5 w-100 line-ellipsis">
        <span className="label text-blue">
          {Translate('route.transport_modes.title')}
          :
        </span>
        <span className="label text-black font-regular">
          {
            sortedTransportModes.map((transportMode, i, row) => (
              i + 1 === row.length
                ? (
                  `${Translate(`route.transport_modes.${TRANSPORT_MODE_DISPLAYABLE[transportMode]}`)}`
                ) : (
                  `${Translate(`route.transport_modes.${TRANSPORT_MODE_DISPLAYABLE[transportMode]}`)}, `
                )
            ))
          }
        </span>
      </div>
      <div className="collapse arrow align-self-start">
        <svg width="15" height="15" className="f-blue">
          <use xlinkHref="#icon-arrow-down" />
        </svg>
      </div>
    </div>
  );

  return (
    <div className={`home-block ${isHomepage ? 'homepage' : ''}`}>
      <Collapsible
        trigger={renderAccordionHeader()}
        open={opened}
        onOpening={() => setOpened(true)}
        onClosing={() => setOpened(false)}
        tabIndex={0}
        triggerTagName="div"
        triggerClassName=""
      >
        <div className="toggle-content">
          <div>
            <div className="row lr-adjust">
              <TransportModeSwitcher
                icon="icon-tram-circle"
                transportMode="tram"
                checked={transportModes.includes(NAVITIA_TRANSPORT_TYPES.TRAM)}
                switchAction={() => dispatch(switchRouteTransport(NAVITIA_TRANSPORT_TYPES.TRAM))}
              />
              <TransportModeSwitcher
                icon="icon-bus-circle"
                transportMode="bus"
                checked={transportModes.includes(NAVITIA_TRANSPORT_TYPES.BUS)}
                switchAction={() => dispatch(switchRouteTransport(NAVITIA_TRANSPORT_TYPES.BUS))}
              />
              <TransportModeSwitcher
                icon="icon-flex-circle"
                transportMode="flex_aero"
                checked={transportModes.includes(NAVITIA_TRANSPORT_TYPES.FLEX_AREO)}
                switchAction={() => dispatch(switchRouteTransport(NAVITIA_TRANSPORT_TYPES.FLEX_AREO))}
              />
              <TransportModeSwitcher
                icon="icon-batcub-circle"
                transportMode="batcub"
                checked={transportModes.includes(NAVITIA_TRANSPORT_TYPES.BATCUB)}
                switchAction={() => dispatch(switchRouteTransport(NAVITIA_TRANSPORT_TYPES.BATCUB))}
              />
              <TransportModeSwitcher
                icon="icon-vcub-circle"
                transportMode="vcub"
                checked={transportModes.includes(NAVITIA_TRANSPORT_TYPES.VCUB)}
                switchAction={() => dispatch(switchRouteTransport(NAVITIA_TRANSPORT_TYPES.VCUB))}
              />
              <TransportModeSwitcher
                icon="icon-pr-circle"
                transportMode="parking"
                checked={transportModes.includes(NAVITIA_TRANSPORT_TYPES.CAR)}
                switchAction={() => dispatch(switchRouteTransport(NAVITIA_TRANSPORT_TYPES.CAR))}
              />
              <TransportModeSwitcher
                icon="icon-autocar-circle"
                transportMode="autocar"
                checked={transportModes.includes(NAVITIA_TRANSPORT_TYPES.AUTOCAR)}
                switchAction={() => dispatch(switchRouteTransport(NAVITIA_TRANSPORT_TYPES.AUTOCAR))}
              />
              <TransportModeSwitcher
                icon="icon-sncf-circle"
                transportMode="train"
                checked={transportModes.includes(NAVITIA_TRANSPORT_TYPES.TRAIN_TER)}
                switchAction={() => dispatch(switchRouteTransport(NAVITIA_TRANSPORT_TYPES.TRAIN_TER))}
              />
            </div>
            {
              transportModesError
                ? <p className="alert alert-error">{transportModesError}</p>
                : null
            }
          </div>
        </div>
      </Collapsible>
    </div>
  );
};

export default RoutesSearchPreferences;
