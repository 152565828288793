import React, { useEffect, useState } from 'react';
import { TailSpin as Loader } from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addPlace, removePlace } from '../../../actions/FavoritesActions';
import { ROUTE_TAGGING_ACTION } from '../../../config/routes';

const FavoritePlaceButton = (props) => {
  const { place, taggingAction } = props;
  const loading = useSelector(state => state.favorites.loading);
  const favoritePlaces = useSelector(state => state.favorites.places);
  const [isFavorite, setIsFavorite] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let favorite = favoritePlaces.find(
      favoritePlace => favoritePlace.objectId === place.id,
    );

    // When click to favorite
    if ('objectId' in place) {
      favorite = favoritePlaces.find(
        favoritePlace => favoritePlace.objectId === place.objectId,
      );
    }
    // tagging
    if (favorite !== undefined) {
      taggingAction(ROUTE_TAGGING_ACTION.FAVORITE);
    }
    setIsFavorite(favorite !== undefined);
  }, [favoritePlaces, place]);

  return (
    <div className="container-btn-fav">
      <button
        type="button"
        className="btn-naked"
        onClick={
          isFavorite
            ? () => dispatch(removePlace(place))
            : () => dispatch(addPlace(place))
        }
      >
        {
          loading
            ? <Loader type="TailSpin" color="#039CD2" height={25} width={25} />
            : (
              <svg width="20" height="20" className="f-blue">
                <use xlinkHref={`${isFavorite ? '#icon-star' : '#icon-starempty'}`} />
              </svg>
            )
        }
      </button>
    </div>
  );
};

FavoritePlaceButton.propTypes = {
  place: PropTypes.shape({}).isRequired,
  taggingAction: PropTypes.func.isRequired,
};

export default FavoritePlaceButton;
