import React, { useEffect, useState } from 'react';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import Autosuggest from 'react-autosuggest';
import { TailSpin as Loader } from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES_SEARCH_URL, GEOLOCATE_USER_URL } from '../../../config/config';
import { ROUTE_TAGGING_ACTION, ROUTES_TYPES } from '../../../config/routes';
import shapes from '../../../config/shapes';
import FavoritePlaceButton from '../favourites/FavoritePlaceButton';
import { ENTITY_PARAM } from '../../../config/map';
import { getRecentEntities } from '../../../helpers/RecentSearchHelper';
import { fetchWithTimeout } from '../../../helpers/RequestHelper';
import { setRouteArrival, setRouteDeparture } from '../../../actions/RouteActions';

const geolocInput = {
  type: 'geoloc',
  value: '',
};

const RouteAutosuggest = (props) => {
  const {
    placeholder, placeholderExample, defaultValue, action, icon, taggingAction, fieldType,
  } = props;
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [isTyping, setTyping] = useState(0);
  const [isFocus, setFocus] = useState(false);
  const [autocompleteError, setAutocompleteError] = useState(false);
  const [input, setInput] = useState(null);
  const favoritesPlaces = useSelector(state => state.favorites.places);
  const [geolocateLoading, setGeolocateLoading] = useState(false);
  const [recentSearchItems, setRecentSearchItems] = useState([]);
  const isWebview = useSelector(state => state.app.isWebview);
  const dispatch = useDispatch();

  useEffect(() => {
    const entities = getRecentEntities(ENTITY_PARAM.ADDRESS);
    setRecentSearchItems(entities);
  }, []);

  useEffect(() => {
    setResults([geolocInput, ...favoritesPlaces, ...recentSearchItems]);
  }, [recentSearchItems, favoritesPlaces]);

  useEffect(() => {
    setSearch(defaultValue ? defaultValue.label : '');
  }, [defaultValue]);

  useEffect(() => {
    // On clear button click
    if (input && search === '') {
      input.focus();
      // eslint-disable-next-line no-unused-expressions
      fieldType === 'departure' ? dispatch(setRouteDeparture(null)) : dispatch(setRouteArrival(null));
    }
  }, [search]);

  const renderSuggestion = (entity) => {
    const isGeolocItem = entity.type === 'geoloc';
    const isRecentSearch = typeof entity.isRecentSearch !== 'undefined';

    if (isGeolocItem) {
      return (
        <div className="title-icon display-flex display-full geoloc">
          <div className="m-r-0-25">
            <svg width={18} height={18} className="f-blue">
              <use xlinkHref="#icon-gps-location" />
            </svg>
          </div>
          <span className="text-blue">{Translate('nearbyMe.btn_geoloc')}</span>
        </div>
      );
    }

    let suggestionIcon = '';

    switch (entity.type) {
      case ROUTES_TYPES.STOP_AREA:
        suggestionIcon = 'icon-stoparea'; break;
      case ROUTES_TYPES.PARKS:
        suggestionIcon = 'icon-title-parking'; break;
      case ROUTES_TYPES.VCUBS:
        suggestionIcon = 'icon-vcub'; break;
      default:
        suggestionIcon = 'icon-marker'; break;
    }

    if (isRecentSearch) {
      suggestionIcon = 'icon-search';
    }

    if (favoritesPlaces.includes(entity)) {
      if (entity.place_type !== null) {
        suggestionIcon = `icon-${entity.place_type}`;
        entity.label = entity.name;
      } else if (entity.name === 'home') {
        suggestionIcon = 'icon-home';
      } else if (entity.name === 'office') {
        suggestionIcon = 'icon-work';
      } else {
        suggestionIcon = 'icon-marker';
      }
    }

    return (
      <div className="title-icon display-flex display-full">
        <div className="m-r-0-25">
          <svg width={18} height={18} className="f-light">
            <use xlinkHref={`#${suggestionIcon}`} />
          </svg>
        </div>
        <span>{entity.label || ('routes' in entity ? entity.routes[0].name : entity.name)}</span>
      </div>
    );
  };

  const fetchAutocomplete = async (value) => {
    try {
      const referer = isWebview ? 'webview' : 'www';
      const schedulesQuery = await fetchWithTimeout(`${ROUTES_SEARCH_URL}/${value}?referer=${referer}`, 5000);
      const schedules = await schedulesQuery.json();
      setResults([geolocInput, ...schedules]);
    } catch (e) {
      setAutocompleteError(true);
      // eslint-disable-next-line no-console
      console.error(`Une erreur s'est produite lors de la récupération de l'autocomplete : ${e}`);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    if (value.trim() !== '') {
      setAutocompleteError(false);

      if (isTyping) {
        clearTimeout(isTyping);
      }

      setTyping(setTimeout(() => fetchAutocomplete(value), 200));
    } else {
      clearTimeout(isTyping);
      setTimeout(() => setResults([geolocInput, ...favoritesPlaces, ...recentSearchItems]), 200);
    }
  };

  const clearInput = () => {
    setSearch('');
    // eslint-disable-next-line no-unused-expressions
    fieldType === 'departure' ? dispatch(setRouteDeparture(null)) : dispatch(setRouteArrival(null));
    setResults([geolocInput, ...favoritesPlaces, ...recentSearchItems]);
  };

  // eslint-disable-next-line react/prop-types
  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    if (isFocus) {
      containerProps.className = `${containerProps.className} react-autosuggest__suggestions-container--open`;
    }

    return (
      <div {...containerProps}>
        {
          search.length && results.length === 0 && isFocus
            ? (
              <ul className="react-autosuggest__suggestions-list">
                <li className="react-autosuggest__suggestion react-autosuggest__suggestion--first">
                  <div className="title-icon display-flex display-full">
                    <span className="autosuggest__message text-light m-l-0-5 m-md-l-0">{Translate('schedules.search.no_result')}</span>
                  </div>
                </li>
              </ul>
            )
            : children
        }
      </div>
    );
  };

  const renderInputComponent = inputProps => (
    <div onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
      <label className={isFocus || search.trim().length > 0 ? 'text-blue' : 'placeholder'} htmlFor={`autosuggest_${placeholder}`}>
        <span className="label">{Translate(placeholder)}</span>
        <span className="example">{Translate(placeholderExample)}</span>
        {geolocateLoading ? <Loader type="TailSpin" color="#039CD2" height={15} width={15} /> : false}
      </label>
      {
        icon
          ? (
            <svg width="20" height="20" className="picto">
              <use xlinkHref={`#${icon}`} />
            </svg>
          )
          : null
      }
      <input
        {...inputProps}
        ref={inputRef => setInput(inputRef)}
        autoComplete="off"
        id={`autosuggest_${placeholder}`}
        placeholder={`${Translate(placeholder)}`}
      />
      {
        defaultValue
        && 'label' in defaultValue
        && defaultValue.label === search
          ? <FavoritePlaceButton place={defaultValue} taggingAction={taggingAction} />
          : null
      }
      {
        search.trim().length > 0
          ? (
            <span
              className="react-autosuggest__clear"
              onClick={() => clearInput()}
              onKeyPress={() => clearInput()}
              role="button"
              tabIndex="0"
            >
              <svg width="12" height="12" className="f-blue">
                <use xlinkHref="#icon-close" />
              </svg>
            </span>
          )
          : null
      }
    </div>
  );

  const onSuggestionSelected = (event, { suggestion }) => {
    setSearch('');
    if (suggestion.type === 'geoloc') {
      setGeolocateLoading(true);
      navigator.geolocation.getCurrentPosition(async (position) => {
        const geolocQuery = await fetchWithTimeout(`${GEOLOCATE_USER_URL}/${position.coords.longitude};${position.coords.latitude}`, 5000);
        const geolocJson = await geolocQuery.json();

        setGeolocateLoading(false);
        setSearch(geolocJson.label);
        action(geolocJson);
      }, (e) => {
        setGeolocateLoading(false);
        console.error('Error when geolocating user', e);
        alert(Translate('global.no_geolocation_available'));
      });
      taggingAction(ROUTE_TAGGING_ACTION.LOCATED);
    } else {
      setSearch('label' in suggestion ? suggestion.label : suggestion.name);
      action(suggestion);
      if (suggestion.isRecentSearch !== undefined && suggestion.isRecentSearch === true) {
        taggingAction(ROUTE_TAGGING_ACTION.LAST_SEARCH);
      } else {
        taggingAction(ROUTE_TAGGING_ACTION.INPUT_ADDRESS);
      }
    }
  };

  return (
    <div className={search !== undefined && search.trim().length > 0 && results.length === 0 ? 'no-result' : ''}>
      <Autosuggest
        suggestions={results}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => setResults([geolocInput])}
        focusInputOnSuggestionClick={false}
        getSuggestionValue={suggestion => suggestion.label}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onSuggestionSelected}
        shouldRenderSuggestions={() => true}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={{
          value: search,
          onChange: e => setSearch(e.target.value),
        }}
        onMouseEnter={e => e.preventDefault()}
        onMouseLeave={e => e.preventDefault()}
      />
    </div>
  );
};

RouteAutosuggest.propTypes = {
  placeholder: PropTypes.string.isRequired,
  placeholderExample: PropTypes.string,
  icon: PropTypes.string,
  defaultValue: PropTypes.shape(shapes.destination),
  action: PropTypes.func.isRequired,
  taggingAction: PropTypes.func.isRequired,
  fieldType: PropTypes.string,
};

RouteAutosuggest.defaultProps = {
  icon: null,
  placeholderExample: null,
  defaultValue: null,
  fieldType: 'departure',
};

export default RouteAutosuggest;
