import React from 'react';
import PropTypes from 'prop-types';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';

const TransportModeSwitcher = (props) => {
  const {
    icon, transportMode, checked, switchAction,
  } = props;

  return (
    <div className="col-xs-3 transport p-t-0-5">
      <div className={`toggle-input display-flex wrap ${checked ? 'bg-blue-dark' : 'bg-white'}`}>
        <div htmlFor={`${transportMode}-display-switch`}>
          <svg width="48" height="48" className={`${checked ? 'f-white' : 'f-blue-dark'}`}>
            <use xlinkHref={`#${icon}`} />
          </svg>
        </div>

          <input
            id={`${transportMode}-display-switch`}
            type="checkbox"
            value="1"
            checked={checked}
            onChange={switchAction}
          />

          <label htmlFor={`${transportMode}-display-switch`} className={`text-bold ${checked ? 'text-white' : 'text-blue-dark'}`}>
            {Translate(`route.transport_modes.${transportMode}`)}
          </label>
      </div>
    </div>
  );
};

TransportModeSwitcher.propTypes = {
  icon: PropTypes.string.isRequired,
  transportMode: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  switchAction: PropTypes.func.isRequired,
};

export default TransportModeSwitcher;
